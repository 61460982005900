import React, { Suspense } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Box, Container, Divider, Grid } from "@material-ui/core";
//import { ReactTinyLink } from "react-tiny-link";
 
//const {data} = useQuery(query, variable: {tripid: "e87cb8c2-6dd3-55bd-b7d3-419d1ae047dc"})



const ReactTinyLink = React.lazy(
  () => new Promise(resolve => {
    const { ReactTinyLink: component } = require(
      'react-tiny-link' /* webpackChunkName: "pc-GoldPayWallDialogWithFeaturesList" */
    );
    resolve({ default: component });
  })
);



const TestPage = () => {

  return(
    <React.Fragment>
    <SEO image="something"></SEO>
    <div>test</div>
    {typeof window !== "undefined" ?
    <Suspense fallback={null}>
        <ReactTinyLink
        cardSize="small"
        showGraphic={true}
        maxLine={2}
        minLine={1}
        url="https://www.amazon.com/Steve-Madden-Mens-Jagwar-10-5/dp/B016X44MKA/ref=lp_18637582011_1_1?srs=18637582011&ie=UTF8&qid=1550721409&sr=8-1"
        />
    </Suspense>:null}
    
    </React.Fragment>
  )
  
};

export default TestPage


